import { getRequest, patchRequest, postRequest } from "./MakeCall";

const getUserProfileData = async () => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/user_profile?user_id=eq." +
      localStorage.getItem("userId");
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await getRequest(url, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const getUserProfileDataAll = async () => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/get_user_data_for_cv2_v2";
    const requestData = {
      user_id_data: localStorage.getItem("userId"),
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateUserProfileData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/user_profile?user_id=eq." +
      localStorage.getItem("userId");

    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await patchRequest(url, dataToUpdate, accessToken);

    console.log(response);
    console.log(dataToUpdate);

    return response;
  } catch (error) {
    console.error(error);
  }
};
const updateUserEducationData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_education";
    const requestData = {
      user_id_data: localStorage.userId,
      education_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};
const updateUserReferenceData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_reference";
    const requestData = {
      user_id_data: localStorage.userId,
      reference_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateCareerSummaryData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/rpc/update_career_summary";
    const requestData = {
      user_profile_id: localStorage.userId,
      new_career_summary: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateUserExperienceData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/rest/v1/rpc/update_user_experience_v2";
    const requestData = {
      user_id_data: localStorage.userId,
      experience_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateUserCourseData = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_courses";
    const requestData = {
      user_id_data: localStorage.userId,
      course_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateUserHobbie = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_hobbies";
    const requestData = {
      user_id_data: localStorage.userId,
      hobbies_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};
const updateUserLanugage = async (dataToUpdate) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_language";
    const requestData = {
      user_id_data: localStorage.userId,
      language_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const updateUserSkillData = async (dataToUpdate) => {
  try {
    dataToUpdate = dataToUpdate.map(item => {
      return {
        ...item,
        skill_rating: item.rating,
      };
    });
    dataToUpdate = dataToUpdate.map(item => {
      const { rating, type, ...rest } = item;
      return rest;
    });
    console.log('etai dilam', dataToUpdate);
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/update_user_skill";
    const requestData = {
      user_id_data: localStorage.userId,
      skill_data: dataToUpdate,
    };
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};
const isFeedbackReq = async (requestBody) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/is_feedback_require";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestBody, accessToken);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const insertFeedback = async (requestBody) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/insert_user_feedback";
    const accessToken =
      localStorage.getItem("accessToken") &&
      "Bearer " + localStorage.getItem("accessToken");
    const response = await postRequest(url, requestBody, accessToken);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const genarateCv = async () => {
  try {
    const url = process.env.REACT_APP_FIRBASE_END_POINT + "/cv/generatorv2";
    // const url = "http://localhost:5001/shomvob-2eed9/asia-southeast1/shomvobApiServiceV2/api/v2/cv/generatorv2";
    const requestData = {
      user_id: localStorage.userId,
      user_access_token: localStorage.accessToken,
    };
    const accessToken = process.env.REACT_APP_FIRBASE_KEY;
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const refreshAccessToken = async () => {
  try {
    const requestData = {
      refresh_token: localStorage.getItem("refreshToken"),
    };
    const url =
      process.env.REACT_APP_SUPABASE_URL +
      "/auth/v1/token?grant_type=refresh_token";
    const accessToken = process.env.REACT_APP_FIRBASE_KEY;
    const response = await postRequest(url, requestData, accessToken);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getOTP = async (phone) => {
  try {
    const url =
      process.env.REACT_APP_FIRBASE_END_POINT + "/otp/phone?is_retry=0";
    const requestData = {
      phone: "88" + phone,
    };
    const accessToken = process.env.REACT_APP_FIRBASE_KEY;
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const otpMatch = async (phone, otp) => {
  try {
    const url = process.env.REACT_APP_FIRBASE_END_POINT + "/otp/validate";
    const requestData = {
      phone: "88" + phone,
      otp: otp,
    };
    const accessToken = process.env.REACT_APP_FIRBASE_KEY;
    const response = await postRequest(url, requestData, accessToken);

    return response;
  } catch (error) {
    console.error(error);
  }
};

const insertEasyApplyData = async (requestBody, token) => {
  try {
    const url =
      process.env.REACT_APP_SUPABASE_URL + "/rest/v1/rpc/easy_apply";
    const accessToken =
      "Bearer " + token;
    const response = await postRequest(url, requestBody, accessToken);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export {
  getUserProfileData,
  getUserProfileDataAll,
  updateUserProfileData,
  updateUserExperienceData,
  updateUserReferenceData,
  updateUserCourseData,
  updateUserEducationData,
  updateUserSkillData,
  genarateCv,
  isFeedbackReq,
  insertFeedback,
  refreshAccessToken,
  getOTP,
  otpMatch,
  insertEasyApplyData,
  updateCareerSummaryData,
  updateUserHobbie,
  updateUserLanugage
};
